
.commodityTab {
    /deep/ .el-tabs__header {
        margin: 0;
        .el-tabs__nav-wrap {
            padding: 0 30px;
        }
        .el-tabs__active-bar {
            background-color: #FD4446;
        }
        .el-tabs__item.is-active {
            color: #FD4446;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
            background-color: #D1D2DB;
        }
    }
    /deep/ .el-tabs__item {
        height: 80px;
        line-height: 80px;
        font-size: 20px;
        &:hover {
            color: #FD4446;
        }
    }
}

.right-wrapper {
    /*width: 1%;*/
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    .right-header {
        height: 80px;
        background: #fff;
        border-bottom: 1px solid #D1D2DB;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .header-tab {
            height: 100%;
            position: absolute;
            left: 30px;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #333333;
            .activeSelect {
                a {
                    display: block;
                    height: 100%;
                    color: #FD4446 !important;
                    border-bottom: 2px solid #FD4446 !important;
                }
            }
            .item-tab {
                height: 100%;
                margin-right: 25px;
                cursor: pointer;
                line-height: 81px;
                border-bottom: 2px solid transparent;
                font-size: 20px;
                a {
                    display: block;
                    color: #333333;
                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }
    }
    /deep/ .right-content {
        height: 1%;
        flex: 1;
        .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                min-width: 900px;
                /*height: 100%;*/
            }
            .right-wrapper-content {
                padding: 20px 30px 20px 40px;
                background: #F7F9FB;
                box-sizing: border-box;
            }
        }
    }
}
