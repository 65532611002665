
.student-container {
    background: #F7F9FB;
    width: 100vw;
    height: 100vh;
    display: flex;
    .left-wrapper {
        width: 280px;
        background: #2C405F;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        .left-header {
            text-align: center;
            .logo {
                margin-top: 36px;
                display: inline-block;
                width: 60px;
                height: 60px;
                background: #FD4446;
                border-radius: 10px;
                text-align: center;
                line-height: 60px;
                .iconfont {
                    display: block;
                    font-size: 33px;
                    color: #fff;
                }
            }
            .theme {
                margin-top: 22px;
                font-size: 28px;
                color: #fff;
                line-height: 1;
            }
        }
        .left-menu-wrapper {
            margin-top: 50px;
            height: 1%;
            flex: 1;
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .left-menu {
                height: 100%;
                background: transparent;
                border-right: none;
            }
            .el-menu-item {
                height: 60px;
                line-height: 60px;
                padding: 0!important;
                .nav-item {
                    height: 100%;
                    color: #F7F9FB;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px 0 40px;
                    position: relative;
                    transition: all .3s;
                    .iconfont {
                        color: #F7F9FB;
                        font-size: 24px;
                        margin-right: 20px;
                        transition: all .3s;
                    }
                }
                &:focus, &:hover, &.is-active {
                    background-color: #25354F;
                }
                &.is-active {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 2px;
                        height: 100%;
                        background: #FF4400;
                    }
                }
            }
        }
        .left-download {
            margin-bottom: 100px;
            color: #fff;
            .download-item {
                padding: 0 25px 0 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                .right {
                    color: #FD4446;
                }
            }
        }
    }
    .right-wrapper {
        width: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;
        .right-header {
            height: 80px;
            background: #fff;
            border-bottom: 1px solid #D1D2DB;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .countdown {
                font-size: 18px;
                color: #FD4446;
                font-weight: 500;
                .time {
                    margin-left: 10px;
                }
            }
            .user-wrapper {
                position: absolute;
                right: 30px;
                display: flex;
                .user-info {
                    display: flex;
                    align-items: center;
                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .user-name {
                        margin-left: 6px;
                    }
                }
                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    .iconfont {
                        font-size: 20px;
                    }
                    span {
                        margin-left: 6px;
                    }
                    &:hover {
                        color: #1122D8;
                    }
                }
            }
        }
        /deep/ .right-content {
            height: 1%;
            flex: 1;
            .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    min-width: 900px;
                    /*height: 100%;*/
                }
                .right-wrapper-content {
                    padding: 20px 30px 20px 40px;
                    box-sizing: border-box;
                }
            }
        }
    }
}
/deep/ p {
    margin: 0;
}
